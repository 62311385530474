import React, { useContext, useMemo } from 'react';
import { CertificationContext } from 'contexts/certifications';
import { SessionContext } from 'contexts/session';
import {
  CertificationsContainer,
  ColumnContainer,
  ColumnNameIcon,
  Container,
  PaginationContainer,
  Title,
} from './styles';
import { Table, User } from 'assets/styles/styles';
import CertificationsFilters from '../CertificationsFillters/CertificationsFilters';
import { capitalize, formatDate, formatScore } from 'utils/helpers';
import { Spinner } from 'components/loadings';
import { Pagination } from 'components/Table';
import Tooltip from 'components/tooltip';
import { certifications } from 'constants/certifications';
import ButtonIcon from 'components/buttonIcon';
import Icon from 'components/icons';
import displayTeams from 'components/displayTeams/DisplayTeams';

interface CertificationTableProps {
  noMobile?: boolean;
}

const CertificationTable: React.FC<CertificationTableProps> = ({ noMobile = false }) => {
  const { actions, filters, usersTable, fetchingTable, fetching, usersCount } =
    useContext(CertificationContext);

  const { isImpersonated } = useContext(SessionContext);

  const isFetching = useMemo(() => fetching || fetchingTable, [fetching, fetchingTable]);

  const tableColumns =
    'minmax(0, 2fr) minmax(0, 2fr) minmax(101px, 1fr) minmax(0, 1.45fr) minmax(0, 1.55fr) minmax(0, 1.11fr) minmax(0, 1.11fr) minmax(0, 0.36fr)';
  const withEmptySymbol = true;

  const handleCertificationLink = (url: string) => () => {
    window.open(url, '_blank');
  };

  interface TooltipProps {
    title: string;
    color?: string;
    placement?: 'top' | 'right' | 'bottom' | 'left';
  }

  const InfoIconTooltip = ({ title, color = '', placement = 'right' }: TooltipProps) => {
    const fillColor = color || 'var(--lilac)';
    return (
      <Tooltip
        title={title}
        placement={placement}
        overlayInnerStyle={{ textAlign: 'center', maxWidth: '250px' }}
      >
        <Icon data-testid="info-inactive-icon" icon="Info" fill={fillColor} size="small" />
      </Tooltip>
    );
  };

  const displayCertification = (certification: string): string => {
    const cert = Object.values(certifications).find((cert) => cert.short_name === certification);
    return cert?.name;
  };

  const handlePaginationChange = (key, value) => {
    const reset_page = key === 'page_size' ? { page: 1 } : {};
    actions.onChangeFilters({ ...filters, [key]: Number(value), ...reset_page });
  };

  const currentAttempt = (attempts, pos) => {
    const attemptLength = attempts?.length;
    if (!attemptLength) return null;
    if (attemptLength === 1) {
      if (pos === 1) return attempts[0];
      return null;
    }
    return pos === 1 ? attempts[1] : attempts[0];
  };

  const formatAttemptResult = (attempts, pos): JSX.Element => {
    const attempt = currentAttempt(attempts, pos);
    if (!attempt) return <>-</>;

    const normalizedStatus = attempt.status?.toLowerCase().replace(/\s+/g, '_') ?? '';
    const isAttemptTimedOut = normalizedStatus === 'timed_out';
    const isScoreNull = attempt.score === null;

    if (!isAttemptTimedOut && isScoreNull) return <>-</>;

    const scoreRow = isAttemptTimedOut ? 'Time Expired' : formatScore(attempt.score);
    const labelRow = isAttemptTimedOut ? 'Failed' : capitalize(attempt.status);

    return (
      <ColumnContainer>
        <div>{scoreRow}</div>
        <div style={{ color: labelRow === 'Passed' ? 'var(--positive-dark)' : undefined }}>
          {labelRow}
        </div>
      </ColumnContainer>
    );
  };

  const formatExpirationDate = (attempt): JSX.Element => {
    const { expiration_date, is_expired, expires_soon, days_to_expire } = attempt;
    return (
      <ColumnContainer>
        <div>{formatDate(expiration_date, withEmptySymbol)}</div>
        {is_expired && <div style={{ color: 'var(--bright-gray)' }}>Expired</div>}
        {expires_soon && (
          <div style={{ color: 'var(--egg-orange)' }}>{expiringLabel(days_to_expire)}</div>
        )}
      </ColumnContainer>
    );
  };

  const expiringLabel = (days_to_expire: number): string => {
    if (days_to_expire === 0) return 'Expired today';
    return `Expiring in ${days_to_expire} ${days_to_expire > 1 ? 'days' : 'day'}`;
  };

  const tableHasData = useMemo(() => usersTable?.length > 0, [usersTable]);

  const tableHeight = useMemo(() => {
    const minHeigth = 390;
    if (!usersTable || isFetching) return `${minHeigth}px`;
    const userTableHeight = 130 + usersTable.length * 54;
    return `${Math.max(userTableHeight, minHeigth)}px`;
  }, [usersTable, isFetching]);

  const expireSoonRows = useMemo(() => {
    const rows = usersTable?.filter((row) => row?.expires_soon)?.length;
    return Math.min(rows, filters.page_size);
  }, [usersTable]);

  const expireSoon = (index: number, expires_soon: boolean) => {
    const currentIndex = index + 1;
    if (!expires_soon || currentIndex > expireSoonRows) return null;
    return {
      firstRow: currentIndex === 1,
      middleRow: currentIndex > 1 && currentIndex < expireSoonRows,
      lastRow: currentIndex === expireSoonRows,
    };
  };

  const canExtendVoucher = (expiresSoon: boolean, attempts: any[]): boolean =>
    expiresSoon && (!attempts || attempts.length === 0);

  const stickyOffset = useMemo(() => (isImpersonated ? 131 : 65), [isImpersonated]);

  return (
    <Container noMobile={noMobile}>
      <Title>Assigned vouchers</Title>
      <CertificationsContainer>
        <CertificationsFilters />
        <Table.Wrapper
          style={{ width: '100%', justifyContent: 'space-between', height: tableHeight }}
        >
          {isFetching && (
            <Table.LoadingWrapper>
              <div>
                <Spinner active={isFetching} size="50" />
              </div>
            </Table.LoadingWrapper>
          )}
          {!isFetching && tableHasData && (
            <>
              <Table.Container>
                <Table.Thead stickyOffset={stickyOffset}>
                  <Table.Tr borderBottom={true} columns={tableColumns}>
                    <Table.Th>User</Table.Th>
                    <Table.Th>Team(s)</Table.Th>
                    <Table.Th>
                      <ColumnNameIcon style={{ marginRight: '2px' }}>Certification</ColumnNameIcon>
                      <InfoIconTooltip title="Certification short name" />
                    </Table.Th>
                    <Table.Th>
                      <ColumnNameIcon style={{ marginRight: '2px' }}>Assigned date</ColumnNameIcon>
                      <InfoIconTooltip title="Date voucher was assigned to user" />
                    </Table.Th>
                    <Table.Th>
                      <ColumnNameIcon style={{ marginRight: '2px' }}>
                        Expiration date
                      </ColumnNameIcon>
                      <InfoIconTooltip title="Voucher expiration date" />
                    </Table.Th>
                    <Table.Th>
                      <ColumnNameIcon style={{ marginRight: '2px' }}>Attempt 1</ColumnNameIcon>
                      <InfoIconTooltip title="First exam attempt results" />
                    </Table.Th>
                    <Table.Th>
                      <ColumnNameIcon style={{ marginRight: '2px' }}>Attempt 2</ColumnNameIcon>
                      <InfoIconTooltip title="Second exam attempt results: Must be completed within 14 days of the first attempt" />
                    </Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody height="54px">
                  {!isFetching &&
                    usersTable?.length > 0 &&
                    usersTable?.map((report, index) => {
                      const {
                        id,
                        user_name,
                        user_email,
                        teams,
                        certification,
                        assigned_date,
                        expiration_date,
                        accredible_url,
                        attempts,
                        is_expired,
                        expires_soon,
                        days_to_expire,
                      } = report;

                      return (
                        <Table.Tr
                          borderBottom={true}
                          key={`${index}-${id}`}
                          columns={tableColumns}
                          expireSoon={expireSoon(index, expires_soon)}
                        >
                          <Table.Td>
                            <User.Container>
                              <User.Name>{user_name}</User.Name>
                              <User.Email>{user_email}</User.Email>
                            </User.Container>
                          </Table.Td>
                          <Table.Td>{displayTeams({ teams, maxTags: 2 })}</Table.Td>
                          <Table.Td withEllipsis={true}>
                            <Tooltip title={displayCertification(certification)}>
                              {certification}
                            </Tooltip>
                          </Table.Td>
                          <Table.Td>{formatDate(assigned_date, withEmptySymbol)}</Table.Td>
                          <Table.Td>
                            {formatExpirationDate({
                              expiration_date,
                              is_expired,
                              days_to_expire,
                              expires_soon,
                            })}
                          </Table.Td>
                          <Table.Td>{formatAttemptResult(attempts, 1)}</Table.Td>
                          <Table.Td>{formatAttemptResult(attempts, 2)}</Table.Td>
                          <Table.Td
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              height: '36px',
                              minWidth: '36px',
                              width: '100%',
                              justifyContent: 'flex-end',
                            }}
                          >
                            {canExtendVoucher(expires_soon, attempts) && (
                              <Tooltip placement="top" title={'Contact INE to extend'}>
                                <ButtonIcon
                                  data-testid="contact-ine-button"
                                  $secondary
                                  size={'small'}
                                  icon={'Clock'}
                                  onClick={() => window.open('mailto:sales@ine.com', '_blank')}
                                />
                              </Tooltip>
                            )}
                            {accredible_url && (
                              <Tooltip placement="bottomRight" title={'See certification'}>
                                <ButtonIcon
                                  $secondary
                                  size={'small'}
                                  icon={'ExternalLink'}
                                  onClick={handleCertificationLink(accredible_url)}
                                />
                              </Tooltip>
                            )}
                          </Table.Td>
                        </Table.Tr>
                      );
                    })}
                </Table.Tbody>
              </Table.Container>
              <PaginationContainer>
                <Pagination
                  count={usersCount}
                  page_size={filters.page_size}
                  page={filters.page}
                  rowsPerPageList={['10', '20', '30', '50']}
                  onPagesClick={(value, name) => handlePaginationChange(name, value)}
                />
              </PaginationContainer>
            </>
          )}
          {!isFetching && !tableHasData && (
            <Table.EmptyState>
              <Table.EmptyStateTitle>Sorry, we couldn’t find any matches</Table.EmptyStateTitle>
              <Table.EmptyStateText>Please adjust filters and try again </Table.EmptyStateText>
            </Table.EmptyState>
          )}
        </Table.Wrapper>
      </CertificationsContainer>
    </Container>
  );
};

export default CertificationTable;
